import React from "react";
import { Link } from "gatsby";

import * as mainStyles from "../styles/index.module.scss";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { hasServices } from "../components/utils";

const ChainHome = ({ chain, data, testnet }) => {
  return (
    <React.Fragment>
      {/**/}
      {chain.services && chain.services.installation ? (
        <React.Fragment>
          <h2>Installation instructions</h2>
          <p>
            Instructions for installing a node and joining the network. It's based on{" "}
            <a href="https://docs.cosmos.network/main/run-node/cosmovisor.html" target="_blank" rel="noreferrer">
              Cosmovisor <BsBoxArrowUpRight />
            </a>{" "}
            to facilitate easier and faster upgrade handling.
          </p>
          <Link to={(testnet ? "/testnet" : "") + `/${chain.key}/installation`} className={mainStyles.linkBtn}>
            Installation
          </Link>
          <h2>Upgrade instructions</h2>
          <p>
            Is it time for an upgrade? Follow the instructions here to add the latest version to your Cosmovisor
            installation.
          </p>
          <Link to={(testnet ? "/testnet" : "") + `/${chain.key}/upgrade`} className={mainStyles.linkBtn}>
            Upgrade
          </Link>
        </React.Fragment>
      ) : null}
      {/**/}
      {chain.services && chain.services.snapshot ? (
        <React.Fragment>
          <h2>Snapshot service</h2>
          <p>
            Download and start your node from a snapshot to sync with the chain much faster than starting from genesis.
            In some chains this is even required if the upgrade chain from genesis is too complocated, or if state sync
            is not working properly.
          </p>
          <p>Our snapshots are small and optimized to get you started as quickly as possible.</p>
          <Link to={(testnet ? "/testnet" : "") + `/${chain.key}/snapshot`} className={mainStyles.linkBtn}>
            Snapshot
          </Link>
        </React.Fragment>
      ) : null}
      {chain.services && chain.services.stateSync ? (
        <React.Fragment>
          <h2>State sync service</h2>
          <p>
            State syncing your node is a very useful and fast way to get you node up and running, without starting from
            genesis. In a network with a well working state sync service, using it is even faster than starting from a
            snapshot.
          </p>
          <Link to={(testnet ? "/testnet" : "") + `/${chain.key}/state-sync`} className={mainStyles.linkBtn}>
            State-sync
          </Link>
        </React.Fragment>
      ) : null}
      {chain.services && hasServices(chain) ? (
        <React.Fragment>
          <h2>Public services</h2>
          <p>We provide a number of public services, such as RPC, gRPC, REST, seed nodes, etc.</p>
          <Link to={(testnet ? "/testnet" : "") + `/${chain.key}/services`} className={mainStyles.linkBtn}>
            Services
          </Link>
        </React.Fragment>
      ) : null}
      {chain.services && chain.services.networkMap ? (
        <React.Fragment>
          <h2>Network map</h2>
          <p>
            Interested in statistics on geographic distribution and cloud provider concentration of the chain? Check out
            network map to see how decentralized the node operators of the network is.
          </p>
          <Link to={(testnet ? "/testnet" : "") + `/${chain.key}/network-map`} className={mainStyles.linkBtn}>
            Network map
          </Link>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default ChainHome;
